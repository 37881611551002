@import "config";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// body {
//   margin: 0;
//   padding: 0;
//   background: linear-gradient($soft-violet, $soft-blue);
//   color: $dark-grayish-blue;
//   font-family: "Kumbh Sans", sans-serif;
//   font-size: 12px;
//   line-height: 18px;
//   font-weight: 400;
//   min-height: 100vh;
//   display: grid;
//   place-items: center;
// }

// h1 {
//   color: $very-dark-desaturated-blue;
//   font-size: 32px;
//   line-height: 32px;
// }

// button {
//   color: $very-dark-grayish-blue;
//   font-size: 14px;
//   line-height: 14px;
// }

button:focus:not(:focus-visible) {
  outline: 0;
}

button:focus-visible {
  outline: 2px dashed $soft-red;
  outline-offset: 0px;
}

#root {
  width: 100%;
}

// .container {
//   max-width: 1440px;
//   width: 100%;
// }

.font-weight-bold {
  font-weight: 500;
}

// @import "component";
// @import "illustration";
@import "accordion";
@import "attribution";
@import "animation";
// @import "responsive";
