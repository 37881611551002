@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans"), url("ProductSans-Regular.woff") format("woff");
}
.tags {
  display: flex;
  align-items: baseline;
  /* flex-wrap: wrap; */
  /* margin-bottom: 0.75rem; */
}
.tags div {
  font-weight: 500;
  margin-right: 0.5rem;
  font-size: 0.95rem;
}
.tags .tag {
  display: flex;
  cursor: pointer;
  /* margin-bottom: 0.5rem; */
  margin-right: 0.5rem;
  color: white;
  background: #999999;
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  border-radius: 1rem;
  overflow: hidden;
}
.tags .tag:after {
  content: "x";
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  transition: all 0.2s ease;
}
.tags .tag.active:after {
  opacity: 1;
  visibility: visible;
  max-width: 2rem;
  margin-left: 0.4rem;
}
.tags .tag:hover,
.tags .tag.active {
  background: var(--primary);
}

/* Styles for smaller screens */
@media screen and (max-width: 768px) {
  .tags {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    gap: 0.5rem; /* Add spacing between items */
  }

  .tags div {
    font-weight: 500;
    font-size: 0.95rem;
  }

  .tags .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background: #999999;
    padding: 0.3rem 0.6rem;
    font-weight: 600;
    font-size: 0.8rem;
    transition: all 0.2s ease;
    border-radius: 1rem;
    overflow: hidden;
    text-align: center;
    flex: 1 1 calc(33.33% - 1rem); /* Responsive grid: 3 items per row */
  }

  .tags .tag:after {
    content: "x";
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    max-width: 0;
    transition: all 0.2s ease;
  }

  .tags .tag.active:after {
    opacity: 1;
    visibility: visible;
    max-width: 2rem;
    margin-left: 0.4rem;
  }

  .tags .tag:hover,
  .tags .tag.active {
    background: var(--primary);
  }
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
