.sidebar-modal-container2 {
  --smax-w: 340px;
  width: 100%;
  max-width: var(--smax-w);
  min-height: 100vh;
  height: 100%;
  position: fixed;
  left: 0;
  background-color: #fff;
  perspective: 100px;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.4s ease;
  will-change: transform, opacity;
  transform: translateY(var(--smax-w));
  opacity: 0.4;
}
.sidebar-modal-container2:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -5px;
  z-index: -1;
  perspective: 100px;
}
.sidebar-modal-container2:after {
  background-color: #c4c4c4;
  mix-blend-mode: normal;
  opacity: 0.35;
  filter: blur(10px);
}
.sidebar-modal-body {
  background-color: #fff;
  /* padding:25px 20px; */
  width: 100%;
  /* height:100%;
    overflow:auto; */
}
/* .sidebar-modal-container {
    transform:translateX(0);
    opacity:1;
} */
.sidebar-modal-close {
  display: inline-block;
  z-index: 1;
}
.sidebar-modal-close .ti {
  font-size: 13px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  color: #495463;
  text-shadow: none;
  transition: all 0.4s;
  /* box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3); */
}
