
.screen-overlay {
    width:100%;
    height:100%;
    position:fixed;
    display:grid;
    top: 0;
    place-items: center;
    background:rgba(255,255,255,.3);
}

.sp {
    clear: both;
    margin: 20px auto;
}
/* Spinner Sphere */
.sp-sphere {
    border-radius: 50%;
    border-left: 0px #000 solid;
    border-right: 0px #000 solid;
    -webkit-animation: spSphere 1s infinite linear;
    animation: spSphere 1s infinite linear;
  }
  @keyframes spSphere {
    0% {
      border-left: 0px #000 solid;
      border-right: 0px #000 solid;
    }
    33% {
      border-left: 64px #000 solid;
      border-right: 0px #000 solid;
    }
    34% {
      border-left: 0px #000 solid;
      border-right: 64px #000 solid;
    }
    66% {
      border-left: 0px #000 solid;
      border-right: 0px #000 solid;
    }
  }
  .sp-circle {
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px black solid;
    border-radius: 50%;
    -webkit-animation: spCircRot .6s infinite linear;
    animation: spCircRot .6s infinite linear;
  }
  
  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  /* wave */
.sp-wave {
  border-radius: 50%;
  position: relative;
  opacity: 1;
}

.sp-wave:before, .sp-wave:after {
  content: '';
  border: 1px #000 solid;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
}

.sp-wave:before {
  transform: scale(1, 1);
  opacity: 1;
  -webkit-animation: spWaveBe 0.6s infinite linear;
  animation: spWaveBe 0.6s infinite linear;
}

.sp-wave:after {
  transform: scale(0, 0);
  opacity: 0;
  -webkit-animation: spWaveAf 0.6s infinite linear;
  animation: spWaveAf 0.6s infinite linear;
}

@-webkit-keyframes spWaveAf {
  from {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes spWaveAf {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes spWaveBe {
  from {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
@keyframes spWaveBe {
  from {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
