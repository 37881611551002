.accordion {
  width: 100%;

  &__arrow{
    position:absolute;
    right: 27px;
    transform: rotate(180deg);
  }

  &__title {
    margin: 0;
    padding-bottom: 25px;
  }

  &__head {
    // max-width: 350px;
    width: 100%;
    border-bottom: 1px solid $light-grayish-blue;
  }

  &__head-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;

    padding-top: 18px;
    padding-bottom: 18px;

    &::after {
      content: url(../images/icon-arrow-down.svg);
      margin-right: 17px;
      width: 28px;
      height:28px;
      border-radius: 56px;
      background: #FFFFFF;
      border: 2px solid #F3F3F3;
      place-content: center;
      place-items: center;
      display: inline-flex;
      vertical-align: middle;
      padding-bottom: 4px;
    }

    &:hover {
      // color: var(--primary);
    }
  }

  &__body{
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    transition:all .4s ease-out;
    // opacity: 0;
    height: 0px;
  }
}
